import React from 'react'
import { Container } from 'react-bootstrap';
import Particle from '../Particle';
import emailjs from 'emailjs-com';



const SERVICE_ID = "service_1sstdbs";
const TEMPLATE_ID = "template_k3f4wdj";
const PUBLIC_KEY = "istIMUdMeTWG3T32Y";

const ContactForm = () => {
    const handleOnSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
          .then((result) => {
            console.log(result.text);
            alert('Le message a été envoyé avec succès')
          }, (error) => {
            console.log(error.text);
            alert("Impossible d'envoyer le message !")
          });
        e.target.reset()
      };
    return (
        <Container fluid className='about-section'>
            <blockquote className='blockquote mb-0'>
                <div style={{ 
                  height: '81.5vh'
                , display:'flex'}}>
                    <form class="formContainer" onSubmit={handleOnSubmit}>
                        <h2>Contactez moi</h2>
                        <div class="formElement">
                            <label for="from_name">Nom</label>
                            <input type="text" id="from_name" name="from_name" placeholder="Votre nom.." required />
                        </div>
                        <div class="formElement">
                            <label>E-mail</label>
                            <input type="email" id="from_email" name="from_email" placeholder="Votre email.." required />
                        </div>

                        <div class="formElement">
                            <label for="message">Message</label>
                            <textarea name="message" rows="8" cols="30" placeholder="Votre message.." required />
                        </div>
                        <button type='submit' className='formButton'>Envoyer</button>
                    </form>
                </div>
            </blockquote>
       <Particle /> 

     </Container>
     
    )
}

export default ContactForm;