import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SiVisualstudiocode, SiPostman, SiSlack } from 'react-icons/si';
import { PiMicrosoftTeamsLogoFill } from 'react-icons/pi';
import { GoCopilot } from 'react-icons/go';
import { FaLinux, FaWindows, FaGitlab } from 'react-icons/fa';
import { VscGithubInverted } from 'react-icons/vsc';
import { DiGit } from 'react-icons/di';

function Toolstack() {
  return (
    <Row style={{ justifyContent: 'center', paddingBottom: '50px' }}>
      <Col xs={4} md={2} className='tech-icons'>
        <FaLinux />
        <p className='tech-icons-text'>Linux</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <FaWindows />
        <p className='tech-icons-text'>Windows</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <SiVisualstudiocode />
        <p className='tech-icons-text'>Visual Studio Code</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <SiPostman />
        <p className='tech-icons-text'>PostMan</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <SiSlack />
        <p className='tech-icons-text'>Slack</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <GoCopilot />
        <p className='tech-icons-text'>Copilot</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <PiMicrosoftTeamsLogoFill />
        <p className='tech-icons-text'>Teams</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <DiGit />
        <p className='tech-icons-text'>Git</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <FaGitlab />
        <p className='tech-icons-text'>GitLab</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <VscGithubInverted />
        <p className='tech-icons-text'>GitHub</p>
      </Col>
    </Row>
  );
}

export default Toolstack;
