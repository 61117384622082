import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Particle from '../Particle';
import Github from './Github';
import Techstack from './Techstack';
import Aboutcard from './AboutCard';
import homeLogo from '../../Assets/home-main.png';
import Toolstack from './Toolstack';

function About() {
  return (
    <Container fluid className='about-section'>
      <Particle />
      <Container>
        <Row style={{ justifyContent: 'center', padding: '10px' }}>
          <Col
            md={7}
            style={{
              justifyContent: 'center',
              paddingTop: '30px',
              paddingBottom: '50px',
            }}
          >
            <Aboutcard />
          </Col>
          <Col md={5} style={{ paddingTop: '30px', paddingBottom: '50px' }} className='about-img'>
            <img src={homeLogo} alt='home pic' className='img-fluid' />
          </Col>
        </Row>
        <h1 className='project-heading'>
          Languages et <strong className='purple'>technologies </strong>
        </h1>
        <Techstack />

        <h1 className='project-heading'>
          <strong className='purple'>Outils</strong> et méthodologies
        </h1>
        <Toolstack />

        <Github />
      </Container>
    </Container>
  );
}

export default About;
