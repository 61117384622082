import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import myImg from '../../Assets/avatar.png';
import Tilt from 'react-parallax-tilt';
import { AiFillGithub } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';

function Home2() {
  return (
    <Container fluid className='home-about-section' id='about'>
      <Container>
        <Row>
          <Col md={7} className='home-about-description'>
            <h1 style={{ fontSize: '2.5em', paddingBottom: '20px' }}>
              Bienvenue sur mon <strong className='purple'>portfolio</strong> en ligne
            </h1>
            <p className='home-about-body'>
              Je suis un développeur web de 23 ans habitant en{' '}
              <span className='purple'> région parisienne.</span>
              <br />
              <br />
              Je suis actuellement étudiant en 5ème année à{' '}
              <a href='https://www.esiea.fr/' rel='noreferrer' target='_blank'>
                {' '}
                l'ESIEA.
              </a>{' '}
              <br />
              En Alternance à{' '}
              <a href='https://datavalue-consulting.com/' rel='noreferrer' target='_blank'>
                DataValue Consulting
              </a>{' '}
              en tant que développeur web.
              <br />
              <br />
              J'ai suivi une formation en développement, acquérant des compétences en HTML, CSS,
              JavaScript, TypeScript et C#. <br />
              <br />
              Je suis passionné par la création d'expériences numériques exceptionnelles et
              fonctionnelles.
              <br />
              <br />
              Avec une solide expérience dans le domaine du développement informatique, je m'efforce
              continuellement d'allier la créativité à la fonctionnalité pour offrir des solutions
              innovantes. &nbsp;
              <br />
              <br />
              Mes domaines d’expertises sont l’utilisation de
              <span className='purple'> nouvelles technologies</span> et de{' '}
              <span className='purple'>nouveaux produits Web.</span>
              <br />
              <br />
            </p>
          </Col>
          <Col md={5} className='myAvtar'>
            <Tilt>
              <img src={myImg} className='img-fluid' alt='avatar' />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className='home-about-social'>
            <h1>
              N’hésitez pas à me <span className='purple'>contacter </span>sur
            </h1>
            <ul className='home-about-social-links'>
              <li className='social-icons'>
                <a
                  href='https://github.com/Leo-g7'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour  home-social-icons'
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className='social-icons'>
                <a
                  href='https://www.linkedin.com/in/leo-guimard/'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour  home-social-icons'
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
