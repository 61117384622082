import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { DiJavascript1, DiPython, DiMysql  } from 'react-icons/di';
import { SiFirebase, SiTypescript, SiCsharp } from 'react-icons/si';

import { FaCss3, FaHtml5, FaReact, FaVuejs, FaNode } from 'react-icons/fa';
import { GiElephantHead } from "react-icons/gi";

function Techstack() {
  return (
    <Row style={{ justifyContent: 'center', paddingBottom: '50px' }}>
      <Col xs={4} md={2} className='tech-icons'>
        <FaHtml5 />
        <p className='tech-icons-text'>html5</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <FaCss3 />
        <p className='tech-icons-text'>css3</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <SiTypescript />
        <p className='tech-icons-text'>TypeScript</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <DiJavascript1 />
        <p className='tech-icons-text'>Javascript</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <DiPython />
        <p className='tech-icons-text'>Python</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <SiCsharp />
        <p className='tech-icons-text'>C#</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <FaReact />
        <p className='tech-icons-text'>React</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <FaVuejs />
        <p className='tech-icons-text'>Vue.js</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <FaNode />
        <p className='tech-icons-text'>Node.js</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <DiMysql />
        <p className='tech-icons-text'>MySQL</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <SiFirebase />
        <p className='tech-icons-text'>Firebase</p>
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <GiElephantHead />
        <p className='tech-icons-text'>PostgreSQL</p>
      </Col>
    </Row>
  );
}

export default Techstack;
