import React from 'react';
import Card from 'react-bootstrap/Card';

function AboutCard() {
  return (
    <Card className='quote-card-view'>
      {/* <Card.Body> */}
      <blockquote className='blockquote mb-0'>
        <p style={{ textAlign: 'justify' }}>
          <h1 style={{ fontSize: '2em', paddingBottom: '20px' }}>
            <strong className='purple'>Javascript</strong>
          </h1>
          <span className='purple'> Javascript </span> est bien plus qu'un simple langage de
          programmation. Il est devenu l'épine dorsale du développement web moderne, propulsant des
          expériences interactives et dynamiques sur les sites et les applications.
          <br></br>
          <br></br>Maîtriser <span className='purple'> Javascript </span> ouvre les portes d'un
          univers où la créativité et la fonctionnalité se rencontrent pour façonner l'expérience
          utilisateur. C'est en effet dans le but de pouvoir créer une expéricence utilisateur
          unique et créative que j'ai appris le <span className='purple'> Javascript </span> et que
          j'ai continué de travailler avec cette technologie tout au cours de ma carrière
          professionnelle.
          <br></br>
          <br></br>
          <h1 style={{ fontSize: '2em', paddingBottom: '20px' }}>
            <strong className='purple'>Bases de données</strong>
          </h1>
          Dans l'ère numérique contemporaine, l'omniprésence des données en fait le pilier central
          de pratiquement toutes les opérations. Ainsi, la compétence à manipuler les bases de
          données devient essentielle pour le stockage, l'organisation et la récupération efficiente
          de ces informations est cruciales.
          <br></br>
          <br></br>
          C'est dans le but de pouvoir travailler avec de la donnée, ce qui est fondamental pour un
          développeur que j'appris à utiliser des <span className='purple'>
            {' '}
            bases de données.
          </span>{' '}
          Au cours de mon parcours j'ai pu travailler avec plusieurs types de bases de données. Les
          bases de données dites relationnelles telles que <span className='purple'>
            MySQL
          </span> ou <span className='purple'>PostgreSQL.</span> Mais également les base de données
          dites NoSQL telle que <span className='purple'>Firebase</span> par exemple. Ces
          technologies différentes me permettent d'être capable d'avoir un plus grand panel de
          solutions afin de résoudre un problème.
          <br></br>
          <br></br>
          <h1 style={{ fontSize: '2em', paddingBottom: '20px' }}>
            <strong className='purple'>HTML et CSS</strong>
          </h1>
          Dans le monde du développement web, la maîtrise de <span className='purple'>HTML</span> et{' '}
          <span className='purple'>CSS</span> est comparable à posséder les outils d'un architecte
          pour bâtir des expériences en ligne exceptionnelles. Ces langages sont les fondations sur
          lesquelles reposent la structure et l'esthétique des sites web modernes.
          <br></br>
          <br></br>
          Ces technologies me permettent de structurer et contrôler l'apparence d'une page web ce
          qui est crucial afin d'offrir la meilleure expérience utilisateur possible.
        </p>
      </blockquote>
      {/* </Card.Body> */}
    </Card>
  );
}

export default AboutCard;
