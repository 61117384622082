import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Particle from '../Particle';
import softskills from '../../Assets/soft-skills.png';
import team from '../../Assets/equipe.png';
import solving from '../../Assets/solving.png';

function Transverses() {
  return (
    <Container fluid className='about-section'>
      <blockquote className='blockquote mb-0'>
        <Container>
          <Row style={{ justifyContent: 'center', padding: '10px' }}>
            <Col
              md={7}
              style={{
                justifyContent: 'center',
                paddingTop: '30px',
                paddingBottom: '50px',
              }}
            >
              <div>
                <h1 style={{ fontSize: '2em', paddingBottom: '20px' }}>
                  Travail d'<strong className='purple'>équipe</strong>
                </h1>
                La collaboration et le travail d'équipe sont fondamentaux pour le bon déroulement
                d’un projet informatique.
                <br />
                <br />
                Il est évident qu’au sein d’une équipe nous n’avons pas tous les mêmes profils ou
                les mêmes compétences. Il est donc important que ces compétences viennent se
                compléter et s’harmoniser au sein des projets.
                <br />
                <br />
                Il en est de même des idées. Il est essentiel que chacun puisse apporter ses idées,
                ses points de vue, cela pouvant amener à débattre et à faire des choix judicieux au
                mieux pour le projet.
                <br />
                <br />
                Ce que j’aime dans le travail d’équipe, c’est d’être le plus possible à l'écoute de
                mes collègues et de les aider afin de résoudre leurs problèmes.
                <br />
                <br />
                Je suis également capable de fédérer un groupe en donnant une direction à un projet
                et m'assurant que tout le monde ait conscience de son rôle au sein du projet.
                <br />
                <br />
                Prenons l’exemple du projet de réservation de vols. Au début, notre équipe était
                indécise quant au choix de l'architecture à utiliser, chacun ayant sa propre vision.
                <br />
                <br />
                Nous avons décidé de partager nos idées. Nous avons eu la possibilité pour chacun de
                partager notre point de vue en défendant les avantages et les inconvénients de
                chaque proposition. Cela a permis de déterminer la solution la plus appropriée.
                <br />
                <br />
                En fusionnant certains aspects de nos idées respectives, nous avons abouti à une
                solution fonctionnelle que personne d'entre nous n'aurait pu concevoir
                individuellement.
                <br />
                <br />
              </div>
            </Col>
            <Col
              md={5}
              style={{ paddingTop: '30px', paddingBottom: '50px', margin: 'auto' }}
              className='about-img'
            >
              <img src={team} alt='soft skills' className='img-fluid' />
            </Col>
          </Row>
          <Row style={{ justifyContent: 'center', padding: '10px' }}>
            <Col
              md={7}
              style={{
                justifyContent: 'center',
                paddingTop: '30px',
                paddingBottom: '50px',
              }}
            >
              <div>
                <h1 style={{ fontSize: '2em', paddingBottom: '20px' }}>
                  Pensée critique <strong className='purple'>et analytique</strong>
                </h1>
                La pensée critique et analytique est moteur dans un projet. Elle est même
                essentielle dans l’avancée du projet et de sa réussite.
                <br />
                <br />
                Dès le début du projet, c’est important de pouvoir analyser et distinguer les points
                faciles des points difficiles : <br />
                • Ce que je maîtrise <br />
                • Ce que je ne maitrise pas mais j’ai des membres de mon équipe sur lesquels
                m’appuyer <br />
                • Ce que je ne maitrise pas et personne de mon équipe ne maitrise non plus, ce qui
                implique que je vais devoir apprendre et investir un peu plus
                <br />
                <br />
                Dans tout projet, il faut garder une certaine objectivité sur ce que nous
                produisons, quitte à partager avec ses collègues les différents points de vue, pour
                avoir un peu plus de recul.
                <br />
                <br />
                En effet, tout regard critique peut permettre d’améliorer des points que j’aurai pu
                négliger. Echanger avec ses collègues peut donner une autre vision, un autre angle
                d’approche d’un axe qu’on aurait eu du mal à aborder tout seul.
                <br />
                <br />
                Au cours de mes différentes alternances, j’ai pu écouter les précieux conseils de
                mes tuteurs qui m’ont permis de me perfectionner sur certaines compétences.
                <br />
                <br />
                J'utilise également cette compétence au quotidien dans mon travail afin de
                comprendre ce qui est attendu de moi et de réfléchir à ce qui peut être amélioré
                dans mon travail ou dans mon comportement.
                <br />
                <br />
                Cette compétence s'est avérée particulièrement utile dans le cadre de mon travail,
                notamment lors de sessions de revue de code avec mes collègues.
                <br />
                <br />
                Elle me permet habituellement de fournir un retour constructif sur les aspects que
                je pense améliorables dans leur code.
                <br />
                <br />
              </div>
            </Col>
            <Col
              md={5}
              style={{ paddingTop: '30px', paddingBottom: '50px', margin: 'auto' }}
              className='about-img'
            >
              <img src={softskills} alt='soft skills' className='img-fluid' />
            </Col>
          </Row>
          <Row style={{ justifyContent: 'center', padding: '10px' }}>
            <Col
              md={7}
              style={{
                justifyContent: 'center',
                paddingTop: '30px',
                paddingBottom: '50px',
              }}
            >
              <div>
                <h1 style={{ fontSize: '2em', paddingBottom: '20px' }}>
                  Résolution de <strong className='purple'>problèmes</strong>
                </h1>
                La résolution de problèmes est une compétence indispensable qui permet de
                transformer les obstacles en opportunités.
                <br />
                <br />
                Avoir des problèmes ne semble pas très positif en soi. Mais quel projet ne rencontre
                pas des difficultés, des choix difficiles à faire et des problèmes à résoudre ?
                <br />
                <br />
                Dans le monde informatique, on pense que les problèmes se résument souvent aux bugs.
                Mais pas que ? Il peut y avoir une stratégie mal définie au départ, un mauvais choix
                de fonctionnalités, un effectif restreint pour finir le projet, une date de
                livraison mal anticipée, etc….
                <br />
                <br />
                Le mieux pour ne pas résoudre les problèmes est de pouvoir les anticiper au
                préalable.
                <br />
                <br />
                Cependant, résoudre les problèmes est une constante dans tout projet.
                <br />
                <br />
                Il faut faire preuve d’une capacité d’analyse, et parfois d’une forte ouverture
                d’esprit pour pouvoir éventuellement contourner le problème. Lorsque je parviens en
                final à complètement résoudre le problème, c’est une immense satisfaction
                personnelle.
                <br />
                <br />
                Contourner les écueils d’un projet et trouver des solutions innovantes conduit à des
                réalisations remarquables.
                <br />
                <br />
                Grâce à cette compétence je peux effectuer mon travail de façon efficace et être
                plus productif. Je peux également venir en aide à mes collègues dans la résolution
                de leurs problèmes en leur proposant des solutions auxquelles ils n'auraient pas
                pensé.
                <br />
                <br />
                Au cours de mon alternance chez Whyyle, mon tuteur m'a confié la tâche de trouver
                une solution pour mettre en place un générateur de formulaires HTML à partir de
                métadonnées.
                <br />
                <br />
                Pour ce faire, j'ai élaboré initialement deux solutions que j'ai ensuite présentées.
                <br />
                <br />
                À la suite de cette présentation, il a approuvé l'une de mes propositions, ce qui
                m'a permis de l'implémenter de manière efficace et sans accroc.
                <br />
                <br />
                Le processus de réflexion sur les différentes solutions possibles pour résoudre le
                problème, ainsi que les discussions préalables avec mon tuteur avant le
                développement, ont contribué de manière significative à résoudre le problème de
                manière bien plus efficiente que je n'aurais pu le faire autrement.
                <br />
                <br />
              </div>
            </Col>
            <Col
              md={5}
              style={{ paddingTop: '30px', paddingBottom: '50px', margin: 'auto' }}
              className='about-img'
            >
              <img src={solving} alt='soft skills' className='img-fluid' />
            </Col>
          </Row>
        </Container>
      </blockquote>
      <Particle />
    </Container>
  );
}

export default Transverses;
