import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ProjectCard from './ProjectCards';
import Particle from '../Particle';
import intranet from '../../Assets/Projects/intranet.png';
import flight from '../../Assets/Projects/flight.jpg';
import rse from '../../Assets/Projects/rse.jpg';

function Projects() {
  return (
    <Container fluid className='project-section'>
      <Particle />
      <Container>
        <h1 className='project-heading'>
          Mes <strong className='purple'>Projets </strong> récents
        </h1>
        <p style={{ color: 'white' }}>
          Voici quelques projets sur lesquels j'ai travaillé récemment.
        </p>
        <Row style={{ justifyContent: 'center', paddingBottom: '25px' }}>
          <ProjectCard
            imgPath={intranet}
            isBlog={false}
            title='Création d’un outil de recherche Intranet Mazars'
            date='2023'
            description1='Quand je suis arrivé au sein de DataValue Consulting, le constat était que les utilisateurs qui voulaient rechercher des articles en interne n’y parvenaient pas de manière simple et efficace. Le but du projet était donc de faciliter la recherche des articles internes auprès des utilisateurs Mazars en créant un outil de recherche qui facilite la consultation de tous les articles parus en interne. Ce qui m’a plus dès le départ, c’est de pouvoir utiliser pleins de fonctionnalités adaptées aux web, que je ne connaissais pas de prime abord.'
            description2="L'introduction d'une « fuzzy search » grâce à l'intégration d'un plugin Strapi a été cruciale pour améliorer la tolérance aux erreurs de frappe et optimiser la pertinence des résultats. Cette approche a significativement enrichi l'efficacité de la recherche, offrant aux utilisateurs une expérience plus fluide et adaptative. La création d'une interface dédiée pour la visualisation des résultats a constitué une étape essentielle dans le processus de développement. La conception soignée de cette interface a été pensée pour présenter de manière claire et structurée les informations recherchées. Cette présentation visuelle contribue à une expérience utilisateur intuitive, facilitant la compréhension et l'exploitation des résultats de recherche. Parallèlement, la mise en place de filtres constitue une autre dimension cruciale de cette fonctionnalité de recherche. Ces filtres permettent aux utilisateurs de personnaliser leurs résultats en fonction de critères spécifiques, offrant ainsi une flexibilité indispensable. L'ensemble de ces efforts vise à fournir aux utilisateurs de l'intranet de Mazars une expérience de recherche robuste et adaptée à leurs besoins individuels."
            description3='Durant toutes ces phases, j’ai pu exploiter mes connaissances et j’ai également dû acquérir d’autres compétences sur de nouvelles fonctionnalités en essayant de trouver la pertinence de leur utilisation. Cela m’a permis de comprendre combien l’expérience utilisateur est extrêmement importante afin de faire adhérer les utilisateurs à un outil.'
          />
        </Row>
        <Row style={{ justifyContent: 'center', paddingBottom: '25px' }}>
          <ProjectCard
            imgPath={flight}
            isBlog={false}
            title='Flight Booking'
            date='2023'
            description1="Flight Booking est un projet étudiant qui avait pour but de permettre de réserver des vols d'avion. Grâce à une interface performante, mon application avait pour objectif de faciliter la visualisation et la sélection des vols disponibles sur un écran unique, simplifiant ainsi le processus de réservation. L’application offrait également la possibilité de réserver un vol via une adresse e-mail. Afin d’améliorer l’expérience utilisateur, celui-ci pouvait immédiatement identifier son vol."
            description2="L'une des particularités marquantes de Flight Booking réside dans sa capacité à mettre à disposition ses vols à d'autres compagnies aériennes (équipes projet concurrentes) grâce à un Swagger UI. Cette fonctionnalité unique encourage la collaboration, permettant à une compagnie aérienne de rendre ses vols accessibles à d'autres. Cela élargit considérablement les possibilités de voyage, reflétant ainsi un esprit de communauté et de partage au cœur du projet."
            description3="Au niveau technique, Flight Booking adopte une architecture en microservices, apportant une modularité et une évolutivité essentielles à l'application. Cette approche optimise la gestion des différentes facettes de la réservation de vols, contribuant ainsi à une expérience utilisateur flexible, évolutive et efficace. Globalement, Flight Booking se distingue en tant que projet étudiant agréable qui met particulièrement l'accent sur le partage."
            ghLink='https://github.com/germainaubert/flight-booking'
          />
        </Row>
        <Row style={{ justifyContent: 'center', paddingBottom: '25px' }}>
          <ProjectCard
            imgPath={rse}
            isBlog={false}
            title="Développement d'une application web pour Whyyle"
            date='2022'
            description1="Whyyle est une startup de Station F qui accompagne des industriels en matière de Responsabilité Sociétale des Entreprises (RSE). Le but de mon projet était de contribuer à l’application permettant le pilotage des différents sujets : Sécurité, Santé et Environnement dans l'industrie. Ce sujet étant assez innovant, c’était très stimulant de contribuer au développement. De plus, de par Station F, nous avions l’opportunité d’accéder aux outils les plus tendances (Slack, Notion,etc…)."
            description2="En adoptant Vue 3 et TypeScript pour le développement de composants Frontend, j'ai travaillé selon la méthodologie Agile Kanban, assurant ainsi une création d'interface réactive et moderne. La méthodologie DevOps, intégrant Git et Gitlab, a facilité une intégration continue et une livraison efficace des nouvelles fonctionnalités. L'écriture de scripts en Ruby pour l'import Firebase a également été une composante clé de mon rôle, optimisant le processus d'importation de données."
            description3="Ce dont je suis le plus fier :  j'ai développé des composants permettant de trier et filtrer des widgets, améliorant ainsi l'expérience utilisateur en personnalisant les données affichées. Une autre contribution importante a été la création de composants générant des formulaires HTML à partir de métadonnées, simplifiant ainsi la collecte d'informations. En parallèle, j'ai mis en place un script d'import pour convertir des fichiers Excel en données Firebase, renforçant l'efficacité opérationnelle de la plateforme. Mon engagement dans ce projet a été marqué par l'utilisation de technologies modernes et de méthodologies agiles pour soutenir les industriels dans leur démarche RSE. Cette expérience a renforcé ma compréhension des défis liés au développement dans un contexte de startup tout en mettant en avant mon expertise dans la création de solutions techniques innovantes."
          />
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
